import React from 'react';
import Spacer from '../components/atoms/spacer';
import Button from '../components/atoms/button';
import Typography from '../components/atoms/typography';
import TestimonialCard from '../components/molecules/testimonialCard';
import { infoCards, testimonialCards } from '../services/pageData';
import { Row, Col, Visible } from 'react-grid-system';
import { Link, navigate } from 'gatsby';
import InfoCard from '../components/molecules/infoCard';
import ContactForm from '../components/organisms/contactForm';
import HeroBanner from '../components/organisms/heroBanner';
import styled, { css } from 'styled-components';
import family from '../images/family.png';
import panbox from '../images/panbox.png';
import panboxHeroImage from '../images/panbox-hero.png';
import theme from '../styles/theme';
import Icon from '../components/atoms/icon';
import ArrowIconImageRed from '../images/icons/arrow-right-red.png';
import ArrowIconUpWhite from '../images/icons/arrow-up-white.png';
import girlAndMan from '../images/girl-man.png';
import backgroundCircle from '../images/circle-background.png';
import PriceTag from '../components/molecules/priceTag';
import Container from '../components/atoms/container';
import useScreenClassHelper from '../hooks/useScreenClassHelper';
import SEO from '../components/seo';
import Routes from '../services/routes';

const StyledButton = styled(Button)`
  height: 50px;
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
`;

const StyledSubHeader = styled(Typography)`
  font-weight: 300;
`;

const StyledLinkText = styled(Typography)<{ color?: string }>`
  width: auto;
  font-weight: bold;
  margin-right: 0.5rem;
  color: ${({ color, theme }) =>
    color ? theme.colors[color] : theme.colors.secondary};
`;

const Image = styled.img`
  width: 100%;
  margin: auto;
`;

const ContactFormWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
`;

const PageWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.formBackground};
`;

const PriceTagWrapper = styled.div`
  position: absolute;
  ${(props: { isMobile: boolean }) =>
    props.isMobile
      ? null
      : css`
          right: 40px; ;
        `}
`;

const TestimonialWithBackground = styled.div`
  background-image: ${() => `url(${backgroundCircle})`};
  background-repeat: no-repeat;
  background-position: top;
  background-size: ${(props: { isMobile: boolean }) =>
    props.isMobile ? '150% 125%' : '125% 150%'};
`;

const ContainerRightHero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Header = styled(Typography)`
  font-size: 2rem;
`;

const GO_TO_PRODUCT = '/produkter';

type Props = {
  title?: string;
  description?: string;
  descriptionPanBox?: string;
};

const ORDER_PANBOX_BTN_TITLE = 'Beställ panBox nu!';

const NewHomeTemplate: React.FC<Props> = ({
  title = 'Familjen i centrum',
  description = (
    <>
      Var förberedd och minska smittspridning
      <br />- en trygghet för dig och din familj
    </>
  ),
  descriptionPanBox = 'Genom att gå med i panBoxfamiljen så säkerställer du att nödvändiga smittoskyddsprodukter finns tillgängliga för dig och dina nära när ni behöver det. Lådan lagras smidigt hos panBox och kan med ett knapptryck klickas hem till dig vid behov.'
}) => {
  const { IS_MOBILE } = useScreenClassHelper();
  const contactFormTextAlignment = IS_MOBILE ? 'left' : 'center';

  const handleClick = (path: string) => {
    navigate(path);
  };

  return (
    <>
      <SEO
        title="Skyddskit och produkter vid virusutbrott | panBox"
        description="Skydda dig och dina nära och kära vid nästa virusutbrott. Färdiga paket med allt du behöver för att minska risken att bli smittad vid nästa virusutbrott."
      />
      <PageWrapper theme={theme}>
        <HeroBanner
          backgroundColor=""
          leftPane={
            <>
              <Visible sm xs>
                <Spacer spacing={4} />
              </Visible>
              <Container>
                <Row>
                  <Col md={12}>
                    <Spacer spacing={2} />
                    <Header color="primary" variant="header">
                      {title}
                    </Header>
                  </Col>
                  <Col md={12}>
                    <Spacer />
                    <StyledSubHeader variant="h3">
                      {description}
                    </StyledSubHeader>
                  </Col>
                  <Col md={10} sm={6}>
                    <Spacer />
                    <StyledButton onClick={() => handleClick(Routes.ORDER)}>
                      {ORDER_PANBOX_BTN_TITLE}
                    </StyledButton>
                  </Col>
                </Row>
              </Container>
            </>
          }
          rightPane={
            <ContainerRightHero>
              <Spacer spacing={4} />
              <Image alt="panBox innehåll" src={family} />
            </ContainerRightHero>
          }
        />

        <Spacer spacing={IS_MOBILE ? 2 : 4} />
        <Container>
          <Row>
            <Col md={6}>
              <PriceTagWrapper isMobile={IS_MOBILE}>
                <PriceTag />
              </PriceTagWrapper>
              <Image src={panbox} />
            </Col>
            <Col md={6}>
              <Spacer />
              <Typography color="primary" variant="h2">
                Vad är panBox?
              </Typography>
              <Spacer />
              <Typography>{descriptionPanBox}</Typography>
              <Spacer />
              <Typography>
                En trygghet för dig och din familj och en tjänst för samhället
                att hjälpas åt att minska smittspridning.
              </Typography>
              <Spacer spacing={2} />
              <StyledLink to={GO_TO_PRODUCT}>
                <StyledLinkText>Kolla innehållet i panBox!</StyledLinkText>
                <Icon
                  width={25}
                  src={ArrowIconImageRed}
                  onClick={() => navigate(GO_TO_PRODUCT)}
                  alt="pil"
                />
              </StyledLink>
            </Col>
          </Row>
          <Spacer spacing={IS_MOBILE ? 2 : 4} />
          <Typography align="center" variant="h3" color="primary">
            Så här fungerar panBox
          </Typography>
          <Spacer />
          <Row justify="center">
            <iframe
              title="vimeo-player"
              src="https://player.vimeo.com/video/600095675?h=710e6a38d7"
              width="100%"
              height={IS_MOBILE ? '200' : '500'}
              frameBorder="0"
              allowFullScreen
            />
          </Row>
        </Container>
        <Spacer spacing={IS_MOBILE ? 4 : 9} />
        <HeroBanner
          backgroundColor="primary"
          leftPane={
            <Row>
              <Col md={12}>
                <Spacer spacing={3} />
                <Typography color="textSecondary" variant="header">
                  Utvalda produkter
                  <br />
                  för din trygghet.
                </Typography>
              </Col>
              <Col md="content">
                <Spacer />
                <StyledLink to={GO_TO_PRODUCT}>
                  <StyledLinkText color="textSecondary">
                    Se innehållet i din panBox här!
                  </StyledLinkText>
                  <Spacer orientation="horizontal" spacing={0.5} />
                  <Icon
                    src={ArrowIconUpWhite}
                    style={{ transform: 'rotate(90deg)' }}
                    onClick={() => navigate(GO_TO_PRODUCT)}
                    alt="pil"
                  />
                </StyledLink>
              </Col>
            </Row>
          }
          rightPane={
            <ContainerRightHero>
              <Spacer spacing={2} />
              <Image alt="panBox girl" src={panboxHeroImage} />
            </ContainerRightHero>
          }
        />
        <Spacer spacing={5} />
        <Container>
          <Row>
            {infoCards.map((item) => {
              return (
                <Col key={item.title} md={4} xs={12}>
                  <InfoCard height="600px" {...item} />
                </Col>
              );
            })}
          </Row>
        </Container>
        <Spacer spacing={4} />
        <HeroBanner
          backgroundColor="secondary"
          leftPane={
            <Row>
              <Col md={12}>
                <Spacer spacing={3} />
                <Typography color="textSecondary" variant="header">
                  Säkra din panBox idag!
                </Typography>
              </Col>
              <Col md={4}>
                <Spacer />
                <Button primary onClick={() => handleClick('/bestall')}>
                  {ORDER_PANBOX_BTN_TITLE}
                </Button>
              </Col>
            </Row>
          }
          rightPane={
            <ContainerRightHero>
              <Spacer spacing={4} />
              <Image alt="panBox girl" src={girlAndMan} />
            </ContainerRightHero>
          }
        />
        <ContactFormWrapper>
          <Container>
            <Row>
              <Col md={8} offset={{ md: 2 }}>
                <ContactForm
                  header={
                    <>
                      <Typography
                        align={contactFormTextAlignment}
                        color="textSecondary"
                        variant="h2"
                      >
                        Har du frågor om panBox?
                      </Typography>
                      <Spacer />
                      <StyledSubHeader
                        align={contactFormTextAlignment}
                        color="textSecondary"
                      >
                        Skicka ett meddelande till oss så återkommer vi snarast!
                      </StyledSubHeader>
                      <Spacer spacing={2} />
                    </>
                  }
                  termsColor="textSecondary"
                  ctaTitle="Skicka"
                  backgroundColor="primary"
                  isPrimaryButton
                />
              </Col>
            </Row>
          </Container>
          <Spacer spacing={3} />
          <TestimonialWithBackground isMobile={IS_MOBILE}>
            <Spacer spacing={IS_MOBILE ? 10 : 6} />
            <Container>
              <Row>
                {testimonialCards.map((item) => (
                  <Col key={item.testimonial} md={4} xs={12}>
                    <TestimonialCard
                      testimonial={item.testimonial}
                      by={item.by}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
            <Spacer spacing={IS_MOBILE ? 2 : 4} />
          </TestimonialWithBackground>
        </ContactFormWrapper>
      </PageWrapper>
    </>
  );
};

export default NewHomeTemplate;
