import React from 'react';
import { ITestimonialCard } from '../atoms/interfaces';
import Card from '../atoms/card';
import Typography from '../atoms/typography';
import styled from 'styled-components';
import TestimonialPng from '../../images/testimonial.png';
import useScreenClassHelper from '../../hooks/useScreenClassHelper';

const TestimonialWrapper = styled(Card)`
  position: relative;
  display: flex;
  width: auto;
  padding: 2.5rem 1rem 2.5rem 1rem;
  border-radius: 12px;
  @media (min-width: 1000px) {
    height: 150px;
  }
  @media (max-width: 1000px) {
    height: ${(props: { isMobile: boolean }) =>
      props.isMobile ? '15vh' : '240px'};
  }
`;

const TestimonialImage = styled.img`
  width: 50px;
  position: absolute;
  top: -15px;
  left: 1rem;
`;

const Testimonial = styled(Typography)`
  font-style: italic;
  font-weight: lighter;
  line-height: 1.2;
`;

const By = styled(Typography)`
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 6px;
`;

const Inner = styled.div`
  width: 100%;
  min-height: 120px;
`;

const TestimonialCard: React.FC<ITestimonialCard> = ({ testimonial, by }) => {
  const { IS_MOBILE } = useScreenClassHelper();
  return (
    <TestimonialWrapper isMobile={IS_MOBILE}>
      <TestimonialImage src={TestimonialPng} alt="citat" />
      <Inner>
        <Testimonial color="textPrimary" variant={'body'}>
          &quot;{testimonial}&quot;
        </Testimonial>
        <By color="primary" variant={'body'}>
          {by}
        </By>
      </Inner>
    </TestimonialWrapper>
  );
};

export default TestimonialCard;
